import { Controller } from "stimulus";
import { patch } from "@rails/request.js"

export default class extends Controller {
  connect() {
    this.element.onchange = () => {
      this.update_preparation_mode()
     }
  }

  async update_preparation_mode(){
    const preparation_mode = this.element.value
    const base_url = window.location.origin;
    const url = `${base_url}/orders/update_preparation_mode`

    patch(url, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        preparation_mode: preparation_mode,
      }),
    })
  }
}