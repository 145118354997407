import { Controller } from "stimulus";

export default class extends Controller {
  static values = { id: String }
  static targets = [ "notClickable", "input" ]

  connect() {
    // On click toggle hidden class and focus input
    this.element.onclick = () => {
      document.getElementById(this.idValue).classList.toggle('hidden')
      document.getElementById(`${this.idValue}_input`).focus()
    }

    // If not clickable target is present stop the event propagation, this way we can 
    // click on modal content without closing it
    if (this.hasNotClickableTarget) {
      this.notClickableTarget.onclick = (event) => {
        event.stopPropagation()
      }
    }
  }
}
