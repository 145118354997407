import { Controller } from "stimulus";
import "flowbite";

export default class extends Controller {
  connect() {
    // target element that will be dismissed
    const target = document.getElementById(this.data.get("id"))

    // options object
    const options = {
      transition: 'transition-opacity',
      duration: 1000,
      timing: 'ease-out',
    }

    const dismiss = new Dismiss(target, options)

    setTimeout(() => { dismiss.hide() }, 5000)
    
  }
}
