import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['summary', 'toggleIcon'];

  toggle() {
    if (!this.element.open) {
      this.toggleIconTarget.classList.remove('fa-angle-down');
      this.toggleIconTarget.classList.add('fa-angle-up');
      this.summaryTarget.classList.remove('rounded-lg');
      this.summaryTarget.classList.add('rounded-t-lg');
    } else {
      this.toggleIconTarget.classList.remove('fa-angle-up');
      this.toggleIconTarget.classList.add('fa-angle-down');
      this.summaryTarget.classList.remove('rounded-t-lg');
      this.summaryTarget.classList.add('rounded-lg');
    }
  }
}

