import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "frame", "icon" ]

  connect() {
    this.frameTarget.addEventListener("turbo:before-fetch-request", (event) => {
      this.iconTarget.classList.toggle("down");

      if (this.frameTarget.childElementCount > 0) {
        event.preventDefault();

        this.frameTarget.classList.toggle("hidden");
      }
    })
  }
}