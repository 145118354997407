import { Controller } from "stimulus";
import { post } from "@rails/request.js"

export default class extends Controller {
  static values = {
    turboFrame: String
  }

  connect() {
    this.element.onchange = () => {
      this.updateOrders()
     }
  }

  async updateOrders(){
    const tenant = this.element.value
    const base_url = window.location.origin;
    const url = `${base_url}/orders/filter_by_tenant`

    post(url, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        tenant: tenant,
      }),
    })
  }
}