import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.element.addEventListener("keypress", (ev) => {
      if (ev.key == "Enter" && this.formTarget) this.formTarget.submit();
    })
  }

  close() {
    this.element.parentElement.textContent = "";
  }

  refresh() {
    window.location.reload();
  }
}